export const services = () => {
  var toggles = document.querySelectorAll(".category-toggle");

  if (toggles.length > 0) {
    var firstToggle = toggles[0];
    var firstParentItem = firstToggle.closest(".services__list-item");
    var firstTargetId = firstToggle.getAttribute("data-target-id");
    var firstTargetElement = document.getElementById(firstTargetId);

    if (firstParentItem && firstTargetElement) {
      firstParentItem.classList.add("active");
      firstTargetElement.classList.add("active");
    }
  }

  toggles.forEach(function (toggle) {
    toggle.addEventListener("click", function () {
      var targetId = this.getAttribute("data-target-id");
      var targetElement = document.getElementById(targetId);
      var parentItem = this.closest(".services__list-item");

      if (parentItem.classList.contains("active")) {
        parentItem.classList.remove("active");
        targetElement.classList.remove("active");
      } else {
        document
          .querySelectorAll(".services__list-item")
          .forEach(function (item) {
            item.classList.remove("active");
          });
        if (targetElement) {
          targetElement.classList.add("active");
          parentItem.classList.add("active");
        }
      }
    });
  });
};
