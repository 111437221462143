export const slider = () => {
  var slider = new Swiper(".swiper-container", {
    watchSlidesProgress: true,
    slidesPerView: 1,
    spaceBetween: 35,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
};
