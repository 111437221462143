export const information = () => {
  jQuery(document).on("click", ".link_popup", function (e) {
    e.preventDefault();
    var popupId = jQuery(this).data("popup-id");
    jQuery(".popup-info[data-popup-id='" + popupId + "']").fadeIn();
    jQuery("body").addClass("body-class");
  });

  jQuery(document).on("click", ".close-popup", function (e) {
    e.preventDefault();
    jQuery(this).closest(".popup-info").fadeOut();
    jQuery("body").removeClass("body-class");
  });

  jQuery(document).mouseup(function (e) {
    var popup = jQuery(".popup-info:visible");
    if (
      popup.length &&
      !popup.is(e.target) &&
      popup.has(e.target).length === 0
    ) {
      popup.fadeOut();
      jQuery("body").removeClass("body-class");
    }
  });

  jQuery(document).keydown(function (e) {
    if (e.which === 27) {
      jQuery(".popup-info:visible").fadeOut();
      jQuery("body").removeClass("body-class");
    }
  });
};
