export const burger = () => {
  const btns = document.querySelectorAll(".header_burger");
  const menus = document.querySelectorAll(".header_menu_mob");
  const page = document.querySelector("#page");

  btns.forEach((btn) => {
    btn.addEventListener("mouseenter", () => {
      btn.classList.add("hover");
    });
    btn.addEventListener("mouseleave", () => {
      btn.classList.remove("hover");
    });
    btn.addEventListener("click", () => {
      btn.classList.toggle("active");
      btn.classList.remove("hover");
      toggleMenu();
    });
  });

  const toggleMenu = () => {
    menus.forEach((menu) => {
      menu.classList.toggle("scale-y-0");
    });
    page.classList.toggle("active");
    document.querySelector("body").classList.toggle("overflow-hidden");
  };

  const menuItems = document.querySelectorAll(".menu-item-has-children");

  menuItems.forEach((item) => {
    let timeout;

    item.addEventListener("mouseenter", function () {
      if (window.innerWidth > 1024) {
        clearTimeout(timeout);
        item.classList.add("active");
      }
    });

    item.addEventListener("mouseleave", function () {
      if (window.innerWidth > 1024) {
        timeout = setTimeout(() => {
          item.classList.remove("active");
        }, 500);
      }
    });

    const subMenus = item.querySelectorAll(".sub-menu, .sub-sub-menu");
    subMenus.forEach((subMenu) => {
      subMenu.addEventListener("mouseenter", function () {
        if (window.innerWidth > 1024) {
          clearTimeout(timeout);
          item.classList.add("active");
        }
      });

      subMenu.addEventListener("mouseleave", function () {
        if (window.innerWidth > 1024) {
          timeout = setTimeout(() => {
            item.classList.remove("active");
          }, 500);
        }
      });
    });

    const icon = item.querySelector(".menu_icon");
    if (icon) {
      icon.addEventListener("click", function (e) {
        if (window.innerWidth <= 1024) {
          e.preventDefault();
          e.stopPropagation();
          item.classList.toggle("active");
        }
      });
    }
  });

  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  anchorLinks.forEach((link) => {
    link.addEventListener("click", function () {
      document.querySelector("body").classList.remove("overflow-hidden");
      btns.forEach((btn) => btn.classList.remove("active"));
      menus.forEach((menu) => menu.classList.add("scale-y-0"));
      page.classList.remove("active");
    });
  });

  window.addEventListener("hashchange", function () {
    document.querySelector("body").classList.remove("overflow-hidden");
    menus.forEach((menu) => menu.classList.add("scale-y-0"));
    btns.forEach((btn) => btn.classList.remove("active"));
    page.classList.remove("active");
  });
};
