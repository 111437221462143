export const faq = () => {
  var faqTitles = document.querySelectorAll(".faq-title");
  var firstContent = document.querySelector(".faq-content");
  var firstTitle = document.querySelector(".faq-title");
  var firstContentMob = document.querySelector(".faq-content.mob");
  var firstTitleMob = document.querySelector(".faq-title.mob");
  var faqWidth = document.querySelector(".faq_width");

  if (firstContent) {
    firstContent.classList.add("active");
    firstTitle.classList.add("active");
  }

  if (firstContentMob) {
    firstContentMob.classList.add("active");
    firstTitleMob.classList.add("active");
  }

  faqTitles.forEach(function (title) {
    title.addEventListener("click", function () {
      var index = this.getAttribute("data-index");
      var content = document.getElementById("faq-content-" + index);
      var contentMob = document.getElementById("faq-content-mob-" + index);

      if (content && content.classList.contains("active")) {
        content.classList.remove("active");
        title.classList.remove("active");
      } else if (content) {
        document.querySelectorAll(".faq-content").forEach(function (item) {
          item.classList.remove("active");
        });

        document.querySelectorAll(".faq-title").forEach(function (item) {
          item.classList.remove("active");
        });

        content.classList.add("active");
        title.classList.add("active");

        content.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      if (contentMob && contentMob.classList.contains("active")) {
        contentMob.classList.remove("active");
        title.classList.remove("active");
      } else if (contentMob) {
        document.querySelectorAll(".faq-content.mob").forEach(function (item) {
          item.classList.remove("active");
        });

        document.querySelectorAll(".faq-title.mob").forEach(function (item) {
          item.classList.remove("active");
        });

        contentMob.classList.add("active");
        title.classList.add("active");

        contentMob.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    });
  });
};
