import { burger } from "./modules/header/burger.js";
import { slider } from "./modules/home/slider.js";
import { services } from "./modules/home/services.js";
import { information } from "./modules/doctors/information.js";
import { modal } from "./modules/modal.js";
import { review } from "./modules/services/review.js";
import { gallery } from "./modules/services/gallery.js";
import { form } from "./modules/services/form.js";
import { faq } from "./modules/services/faq.js";
import { popup } from "./modules/popup.js";
import { anim } from "./modules/home/anim.js";
document.addEventListener("DOMContentLoaded", function (event) {
  burger();
  modal();
  form();
  popup();
  review();
  gallery();
  information();
  faq();
  slider();
  services();
  anim();
});
