export const anim = () => {
  /* animated blocks START */

  const anim_blocks = document.querySelectorAll(".anim");
  const anim_sections = document.querySelectorAll(".animate-section");

  function scrollAnimation(element, animated) {
    let windowHeight = window.innerHeight;
    let perc = 21;
    if (window.innerHeight > 750) {
      perc = 14;
    }
    let offset = (windowHeight * perc) / 100;

    let elementTop = element.getBoundingClientRect().top;
    let startOffset = elementTop - windowHeight + offset;

    if (startOffset <= 0 && !animated) {
      element.classList.add("animated");
      animated = true;
    }
  }

  // Animate sections
  /*anim_sections.forEach(section => {
      let animated = false;
  
      window.addEventListener('scroll', function() {
          scrollAnimation(section, animated);
      });
  });*/

  // Animate blocks
  anim_blocks.forEach((block) => {
    let animated = false;

    window.addEventListener("scroll", function () {
      scrollAnimation(block, animated);
    });

    window.addEventListener("load", function () {
      setTimeout(function () {
        scrollAnimation(block, animated);
      }, 500);
    });
  });

  /* animated blocks END */
};
